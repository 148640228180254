import bookButton from '../../images/book button.png';
import userDetails from '../../images/user details.png'
import intraOral from '../../images/intra-oral.png'

const steps = [
    { id: 1, title: "01", label: 'Provide your details through "Book now" button', image: bookButton },
    { id: 2, title: "02", label: 'Confirm your slot over Whatsapp!', image: '' },
    { id: 3, title: "03", label: 'Share your Intra-Oral pictures and you are all set for your virtual consultation', image: '' },
]

export const Cards = ({ children, className }) => {
    return <div className={`section-p1 py-2 my-3 ${className}`}>{children}</div>
}

export const Card = ({ title, label, icon, image }) => {
    return <div className="card col">
        <b className="title" color='#CD5C5C'>{title}</b>
        <div className="label">{label}</div>
        <div className="">{icon}</div>
        {/* <img src={image} /> */}
    </div>
}

export const StepsToBookAppointment = () => {
    return <div className="">
        <div className="justify-content-center d-flex font-weight-bold display-4">How it works?</div>
        <div className="container">
            <Cards className="steps-container col">
                {steps.map(step => <Card {...step}></Card>
                )}
            </Cards>
        </div>
    </div>
}